// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import { initialize } from '@mariana-tek/anemone';

document.addEventListener('DOMContentLoaded', () => {
  const mariana = initialize(
    document.querySelector('meta[name="mariana-tek-app-id"]').content,
    document.querySelector('meta[name="mariana-tek-app-client-id"]').content,
    document.querySelector('meta[name="mariana-tek-app-url"]').content,
  );

  mariana.createComponent();
});
